import BasicRadio = require("Everlaw/UI/BasicRadio");
import Button = require("Everlaw/UI/Button");
import Checkbox = require("Everlaw/UI/Checkbox");
import Dom = require("Everlaw/Dom");
import Organization = require("Everlaw/Organization");
import QueryDialog = require("Everlaw/UI/QueryDialog");
import Rest = require("Everlaw/Rest");
import dojo_on = require("dojo/on");

// This must be consistent with base64UrlSafeEncode in SAMLUtils.java!!
export function base64UrlSafeEncode(str: string) {
    return btoa(str).replace("/", "_").replace("+", "-");
}

export function build(
    parent: Node,
    org: Organization,
    includeHeader = true,
): (newOrg: Organization) => void {
    let samlInfo: Organization.SamlInfo;
    const fileInput = Dom.input({ type: "file", class: "hidden", accept: "text/xml" });
    const metadataDownloadLink = Dom.a({ href: "" }, "Download Everlaw service provider metadata");
    const metadataDownloadDiv = Dom.div({ id: "metadata-download" }, metadataDownloadLink);

    dojo_on(fileInput, "change", () => {
        if (fileInput.files.length === 0) {
            return;
        }
        const reader = new FileReader();
        reader.onload = () => {
            Rest.post("updateSamlMetadata.rest", {
                org: org.id,
                content: reader.result,
            }).then(update(org));
        };
        reader.readAsText(fileInput.files[0], "utf-8");
    });

    const uploadButton = new Button({
        label: "Upload",
        width: "one",
        class: "safe skinny hidden",
        onClick() {
            fileInput.click();
        },
    });

    const deleteButton = new Button.IconButton({
        iconClass: "x-20",
        tooltip: "Delete metadata",
        onClick: () => {
            QueryDialog.create({
                title: "Delete SAML metadata",
                prompt:
                    "Deleting the metadata will disable single sign-on for "
                    + org.name
                    + ". Are you sure you want to do this?",
                onSubmit: () => {
                    Rest.post("deleteSamlMetadata.rest", {
                        org: org.id,
                    }).then(update(org));
                    return true;
                },
            });
        },
    });

    const replaceButton = new Button({
        label: "Replace",
        width: "one",
        class: "unsafe skinny hidden",
        onClick() {
            fileInput.click();
        },
    });

    const mfaBypassToggle = Checkbox.asToggleSlider({
        label: "Bypass Everlaw multifactor authentication for authenticated users",
        blockDisplay: true,
        onChange: (checked: boolean) => {
            if (samlInfo.mfaBypassEnabled === checked) {
                return;
            }
            if (checked) {
                QueryDialog.create({
                    title: "Bypass Everlaw multi-factor authentication",
                    prompt:
                        "You should only bypass Everlaw multi-factor authentication if your "
                        + "identity provider also provides MFA. Are you sure you want to do this?",
                    onSubmit: () => {
                        toggleMfa(checked);
                        return true;
                    },
                    onCancel: () => {
                        mfaBypassToggle.set(false);
                        return true;
                    },
                });
            } else {
                toggleMfa(checked);
            }
        },
    });

    const toggleMfa = (bypassEnabled: boolean) => {
        Rest.post("toggleMfaBypass.rest", {
            org: org.id,
            bypassEnabled: bypassEnabled,
        }).then(update(org));
    };

    const authSelect = new BasicRadio(
        [
            { id: Organization.SamlState.DISABLED, display: "Off" },
            { id: Organization.SamlState.REQUIRED, display: "Required" },
            { id: Organization.SamlState.OPTIONAL, display: "Optional" },
        ],
        false,
    );

    authSelect.onChange = (changedTo: BasicRadio.Element) => {
        Rest.post("setSsoState.rest", {
            org: org.id,
            ssoState: changedTo.id,
        }).then(update(org));
    };

    const settingsDiv = Dom.div(
        {},
        Dom.div(
            { id: "authentication-setting" },
            Dom.label({ for: "auth-setting" }, "Authentication setting"),
            Dom.node(authSelect),
        ),
        Dom.node(mfaBypassToggle),
    );

    const metadataInfo = Dom.div();

    Dom.addContent(
        parent,
        Dom.div(
            { id: "saml-section" },
            includeHeader ? Dom.h4("SAML single sign-on") : null,
            metadataDownloadDiv,
            fileInput,
            Dom.div(
                { id: "metadata-upload" },
                metadataInfo,
                Dom.div({ id: "delete-metadata" }, deleteButton.node),
                Dom.div({ id: "upload-button" }, uploadButton.node, replaceButton.node),
            ),
            settingsDiv,
        ),
    );

    const updateOrg = (newOrg: Organization) => {
        org = newOrg;
        Dom.hide([uploadButton, deleteButton, replaceButton, settingsDiv]);
        metadataInfo.textContent = "";
        org
            && Rest.get("getSamlInfo.rest", {
                org: org.id,
            }).then(update(org));
    };

    const update = (forOrg: Organization) => (newSamlInfo: Organization.SamlInfo) => {
        if (forOrg !== org) {
            return; // This update was for a previously selected organization, not the current
        }
        samlInfo = newSamlInfo;

        Dom.hide(uploadButton, !!samlInfo);
        Dom.show([deleteButton, replaceButton, settingsDiv], !!samlInfo);
        Dom.show(metadataDownloadDiv, !!samlInfo);

        if (samlInfo) {
            Dom.setContent(metadataInfo, "Metadata uploaded: " + samlInfo.updateTime);
            mfaBypassToggle.set(samlInfo.mfaBypassEnabled);
            authSelect.select(samlInfo.state);
            metadataDownloadLink.href =
                "/saml2/service-provider-metadata/" + base64UrlSafeEncode(samlInfo.entityId);
        } else {
            Dom.setContent(metadataInfo, "Upload the metadata XML for your identity provider");
            metadataDownloadLink.href = "";
        }
    };

    updateOrg(org);

    return updateOrg;
}
